"use strict";

const allControls = document.querySelectorAll(
    `input:not([type="checkbox"], [type="radio"], [type="hidden"]), select, textarea`
);

function createElement() {
    const iconOptionalElement = document.createElement(`button`);

    iconOptionalElement.type = `button`;
    iconOptionalElement.classList.add(`icon-optional`);
    iconOptionalElement.dataset.call = `popover`;
    iconOptionalElement.dataset.direction = `left`;
    iconOptionalElement.setAttribute(`aria-label`, `Необязательный пункт`);

    return iconOptionalElement;
}

function addIcon(control) {
    control.parentNode.appendChild(createElement());
}

function addIconsOptional(controls) {
    if (!controls) {
        for (let i = 0; i < allControls.length; i++) {
            addIcon(allControls[i]);
        }
    } else {
        for (let i = 0; i < controls.length; i++) {
            addIcon(controls[i]);
        }
    }
}

export {addIconsOptional};
import {expand} from './modules/navbar.js';
import {customSelect} from "./modules/custom-select.js";
import {multiplySelect} from "./modules/multiply-select.js";
import {showHidden} from "./modules/noscript-hidden.js";
import {callModal} from "./modules/modal.js";
import {dropdown} from "./modules/dropdown.js";
import {customImageUpload} from "./modules/custom-image-upload.js";
import {customFileUpload} from "./modules/custom-file-upload.js";
import {collapse} from "./modules/collapse.js";
import {changeDisable} from "./modules/change-disable.js";
import {validateForm} from "./modules/form-validation.js";
import {hidingBlocks} from "./modules/hiding-blocks.js";
import {togglePassword} from "./modules/toggle-password.js";
import {addIconsOptional} from "./modules/optional-control.js";
import {initCheckboxTree} from "./modules/checkbox-tree.js";

showHidden();
expand();
customSelect();
multiplySelect();
callModal();
dropdown();
customImageUpload();
collapse();
customFileUpload();
changeDisable();
validateForm();
hidingBlocks();
togglePassword();
addIconsOptional();
initCheckboxTree();



const showPasswordButtons = document.querySelectorAll("[data-call='toggle-password']");

function iterateButtons() {
    for (let i = 0; i < showPasswordButtons.length; i++) {
        addListeners(showPasswordButtons[i]);
    }
}

function addListeners(button) {
    button.addEventListener("mousedown", (evt) => {
        showPassword(evt, button);
    });

    button.addEventListener("keydown", (evt) => {
        showPassword(evt, button);
    });

    button.addEventListener("mouseup", (evt) => {
        hidePassword(evt, button);
    });

    button.addEventListener("keyup", (evt) => {
        hidePassword(evt, button);
    });
}

function showPassword(evt, button) {
    if (evt.button === 0 || evt.code === "Space") {
        const targetInput = document.querySelector(button.dataset.target);

        targetInput.type = "text";
    }
}

function hidePassword(evt, button) {
    if (evt.button === 0 || evt.code === "Space") {
        const targetInput = document.querySelector(button.dataset.target);

        targetInput.type = "password";
    }
}

function togglePassword() {
    iterateButtons();
}

export {togglePassword};
export function toggleElem(elem, action, isInitial) {
    let formControls = elem.querySelectorAll('input:not([type="checkbox"]), select, textarea');
    let checkboxes = elem.querySelectorAll('input[type="checkbox"]');

    if (action === 'hide') {
        elem.classList.add('d-none');

        for (let i = 0; i < formControls.length; i++) {
            formControls[i].required = false;
            formControls[i].disabled = true;

            if (!isInitial && !formControls[i].readOnly && !formControls[i].dataset.value) {
                formControls[i].value = '';
            }
        }

        if (!isInitial) {
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].dataset.checked !== 'true') {
                    checkboxes[i].checked = false;
                }
            }
        }
    } else if (action === 'show') {
        elem.classList.remove('d-none');
        elem.classList.add('state_ready-to-show');

        setTimeout(function () {
            elem.classList.add('state_show');
        }, 10);

        setTimeout(function () {
            elem.classList.remove('state_ready-to-show', 'state_show')
        }, 300);

        for (let i = 0; i < formControls.length; i++) {
            if (formControls[i].dataset.disabled !== 'true') {
                formControls[i].disabled = false;
            }

            if (formControls[i].dataset.required === 'true') {
                formControls[i].required = true;
            }
        }
    }
}

export function toggleInit(elem) {
    let isAncestorHide = false,
        ancestor = elem.parentNode;

    while (ancestor && ancestor !== document.body) {
        if (
            window.getComputedStyle(ancestor).display === "none" &&
            !ancestor.classList.contains('modal') &&
            !ancestor.classList.contains('multiply-select__options')
        ) {
            isAncestorHide = true;
            break;
        }

        ancestor = ancestor.parentElement;
    }

    if (!isAncestorHide) {
        if (elem.tagName === 'SELECT') {
            for (let i = 0; i < elem.children.length; i++) {
                if (elem.children[i].selected) {
                    if (elem.children[i].dataset.show) {
                        let targetsToShowNames = elem.children[i].dataset.show
                            .replace(/\r|\n/g, '').split(', ');

                        for (let i = 0; i < targetsToShowNames.length; i++) {
                            let targetToShow = document.querySelector(
                                `[data-hidden="${targetsToShowNames[i].trim()}"]`
                            );

                            if (targetToShow) {
                                toggleElem(targetToShow, 'show');
                            }
                        }
                    }

                    if (elem.children[i].dataset.hide) {
                        let targetsToHideNames = elem.children[i].dataset.hide
                            .replace(/\r|\n/g, '').split(', ');

                        for (let i = 0; i < targetsToHideNames.length; i++) {
                            let targetToHide = document.querySelector(
                                `[data-hidden="${targetsToHideNames[i].trim()}"]`
                            );

                            if (targetToHide) {
                                toggleElem(targetToHide, 'hide');
                            }
                        }
                    }
                }
            }
        } else if (elem.tagName === 'INPUT' && (elem.type === 'checkbox' || elem.type === 'radio')) {
            if (elem.dataset.show) {
                let targetsToShowNames = elem.dataset.show.split(', ');

                for (let i = 0; i < targetsToShowNames.length; i++) {
                    let targetToShow = document.querySelector(
                        `[data-hidden="${targetsToShowNames[i]}"]`
                    );

                    if (elem.checked) {
                        if (targetToShow) {
                            toggleElem(targetToShow, 'show');
                        }
                    } else {
                        if (targetToShow) {
                            if (elem.dataset.associate) {
                                let associateElements = document.querySelectorAll(`[data-associate="${elem.dataset.associate}"]`);
                                let isAssociateElementChecked = false;

                                for (let i = 0; i < associateElements.length; i++) {
                                    if (associateElements[i].checked) {
                                        isAssociateElementChecked = true;
                                        break;
                                    }
                                }

                                if (!isAssociateElementChecked) {
                                    toggleElem(targetToShow, 'hide');
                                }
                            } else {
                                if (targetToShow) {
                                    toggleElem(targetToShow, 'hide');
                                }
                            }
                        }
                    }
                }
            }

            if (elem.dataset.hide) {
                let targetsToHideNames = elem.dataset.hide.split(', ');

                for (let i = 0; i < targetsToHideNames.length; i++) {
                    let targetToHide = document.querySelector(
                        `[data-hidden="${targetsToHideNames[i]}"]`
                    );


                    if (elem.checked) {
                        if (targetToHide) {
                            toggleElem(targetToHide, 'hide');
                        }
                    } else {
                        if (targetToHide) {
                            toggleElem(targetToHide, 'show');
                        }
                    }
                }
            }
        }
    }
}

export function addListener(elem) {
    elem.addEventListener('change', function () {
        toggleInit(elem);
    })
}

export function hidingBlocks() {
    let elemsToHide = document.querySelectorAll('[data-hidden]'),
        togglingElems = document.querySelectorAll('[data-call="toggle"]');

    if (elemsToHide.length > 0) {
        for (let i = 0; i < elemsToHide.length; i++) {
            toggleElem(elemsToHide[i], 'hide', true);
        }

        for (let i = 0; i < togglingElems.length; i++) {
            toggleInit(togglingElems[i]);
            addListener(togglingElems[i]);
        }
    }
}
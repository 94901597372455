export function collapse() {
    const collapseControls = document.querySelectorAll('[data-call="collapse"]');
    const deployEvent = new Event("deploy");

    for (let i = 0; i < collapseControls.length; i++) {
        let collapseTarget = document.getElementById(collapseControls[i].getAttribute('aria-controls'));

        collapseControls[i].classList.add('collapse-control');
        collapseTarget.classList.add('collapse-target');

        if (collapseControls[i].getAttribute('aria-expanded') !== 'false') {
            collapseControls[i].classList.add('collapse-control_state_deployed');
            collapseTarget.classList.add('collapse-target_state_deployed');
            collapseTarget.classList.add('collapse-target_state_overflow-visible');
            collapseTarget.style.height = collapseTarget.scrollHeight + 'px';

            setTimeout(function () {
                collapseTarget.style.height = '';
            }, 500);
        } else {
            collapseControls[i].classList.add('collapse-control_state_collapsed');
            collapseTarget.classList.add('collapse-target_state_collapsed');
            collapseTarget.classList.add('collapse-target_state_overflow-hidden');
        }

        collapseControls[i].addEventListener('click', function () {
            collapsing(this, collapseTarget);
        });
    }

    function collapsing(control, target) {
        if (target.classList.contains('collapse-target_state_collapsed')) {
            deployItem(control, target);

            if (control.dataset.bind) {
                const bindControls = document.querySelectorAll(`[data-bind="${control.dataset.bind}"]`);

                for (let i = 0; i < bindControls.length; i++) {
                    if (bindControls[i] !== control) {
                        const bindTarget = document.getElementById(bindControls[i].getAttribute('aria-controls'));

                        collapseItem(bindControls[i], bindTarget);

                        bindControls[i].classList.add('collapse-control_state_collapsed');
                        bindControls[i].classList.remove('collapse-control_state_deployed');
                        bindTarget.classList.add('collapse-target_state_collapsed');
                        bindTarget.classList.remove('collapse-target_state_deployed');
                    }
                }
            }
        } else {
            collapseItem(control, target);
        }
    }

    function deployItem(control, target) {
        target.style.height = target.scrollHeight + 'px';
        control.setAttribute('aria-expanded', 'true');
        target.dispatchEvent(deployEvent);

        control.classList.toggle('collapse-control_state_collapsed');
        control.classList.toggle('collapse-control_state_deployed');
        target.classList.toggle('collapse-target_state_collapsed');
        target.classList.toggle('collapse-target_state_deployed');

        setTimeout(function () {
            target.style.height = '';
            target.classList.remove('collapse-target_state_overflow-hidden');
            //target.classList.add('collapse-target_state_overflow-visible');
        }, 500);
    }
}

export function collapseItem(control, target) {
    if (!target.classList.contains('collapse-target_state_collapsed')) {
        const collapseEvent = new Event("collapse");

        target.style.height = target.scrollHeight + 'px';
        target.classList.remove('collapse-target_state_overflow-visible');
        target.classList.add('collapse-target_state_overflow-hidden');
        target.dispatchEvent(collapseEvent);
        control.setAttribute('aria-expanded', 'false');

        control.classList.toggle('collapse-control_state_collapsed');
        control.classList.toggle('collapse-control_state_deployed');
        target.classList.toggle('collapse-target_state_collapsed');
        target.classList.toggle('collapse-target_state_deployed');

        setTimeout(function () {
            target.style.height = '';
        }, 10);
    }
}
export function dropdown() {
    let dropdownControls = document.querySelectorAll('[data-call="dropdown"]');

    for (let i = 0; i < dropdownControls.length; i++) {
        addListener(dropdownControls[i]);

        let targetDropdown = document.getElementById(dropdownControls[i].getAttribute('aria-controls'));

        changePosition(targetDropdown);
    }

    function addListener(elem) {
        elem.addEventListener('click', function (evt) {
            evt.preventDefault();

            let targetDropdown = document.getElementById(elem.getAttribute('aria-controls')),
                substrate = document.createElement('div');

            if (targetDropdown) {
                if (!targetDropdown.classList.contains('dropdown__target_show')) {
                    changePosition(targetDropdown);

                    targetDropdown.classList.add('dropdown__target_show');
                    substrate.classList.add('dropdown__substrate');
                    substrate.tabIndex = 0;
                    targetDropdown.parentNode.append(substrate);

                    substrate.addEventListener('click', function () {
                        removeDropdown(targetDropdown, elem);
                    });

                    substrate.addEventListener('focus', function () {
                        removeDropdown(targetDropdown, elem);
                    })
                } else {
                    removeDropdown(targetDropdown, elem);
                }
            }
        })
    }

    function removeDropdown(targetDropdown, elem) {
        let substrate = targetDropdown.parentNode.querySelector('.dropdown__substrate');

        targetDropdown.classList.remove('dropdown__target_show');
        substrate.remove();
        elem.focus();
    }

    function changePosition(elem) {
        if (elem.getBoundingClientRect().x + elem.getBoundingClientRect().width >
            document.documentElement.clientWidth) {
            elem.classList.add('dropdown__target_abut');
        }
    }
}
export function changeDisable() {
    const changingElements = document.querySelectorAll('[data-call="change-disable"]');

    for (let i = 0; i < changingElements.length; i++) {
        changeAction(changingElements[i]);
        addListener(changingElements[i]);
    }

    function addListener(elem) {
        elem.addEventListener('change', function () {
            changeAction(elem);
        })
    }

    function changeAction(elem) {
        let target = document.getElementById(elem.getAttribute('aria-controls'));

        target.disabled = !elem.checked;
    }
}
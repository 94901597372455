export function validateForm() {
    const forms = document.querySelectorAll('form');

    let listenersControl = {};

    for (let i = 0; i < forms.length; i++) {
        forms[i].noValidate = true;
        checkServerValidation(forms[i]);
        addListenerForm(forms[i]);
    }

    CustomValidation.prototype = {
        invalidities: [],
        checkValidity: function (input) {
            const validity = input.validity;

            if (validity.patternMismatch) {
                if (input.name.indexOf('phone') + 1) {
                    this.addInvalidity('Введите номер телефона в формате +7 999 999 99 99');
                } else if (input.pattern === '^[^\\s]+$') {
                    this.addInvalidity('В пароле не должно быть пробелов');
                } else {
                    this.addInvalidity('Неправильный формат');
                }
            }

            if (validity.rangeOverflow) {
                let max = input.getAttribute('max');

                this.addInvalidity('Значение должно быть меньше или равно ' + max);
            }

            if (validity.rangeUnderflow) {
                let min = input.getAttribute('min');

                this.addInvalidity('Значение должно быть больше или равно ' + min);
            }

            if (validity.stepMismatch) {
                let step = input.getAttribute('step');

                this.addInvalidity('Значение должно быть кратно ' + step);
            }

            if (validity.tooLong) {
                let maxLength = input.getAttribute('maxlength');

                this.addInvalidity(`Значение должно быть не длиннее ${maxLength} символов`);
            }

            if (validity.tooShort) {
                let minLength = input.getAttribute('minlength');

                this.addInvalidity(`Значение должно быть не короче ${minLength} символов`);
            }

            if (validity.typeMismatch) {
                if (input.type === 'email') {
                    this.addInvalidity('Пожалуйста, введите корректный адрес электронной почты');
                } else {
                    this.addInvalidity('Неправильный формат');
                }
            }

            if (validity.valueMissing) {
                if (input.type === 'checkbox') {
                    this.addInvalidity('Необходимо установить этот флаг');
                } else {
                    this.addInvalidity('Это поле обязательно для заполнения');
                }
            }

            if (input.dataset.call === 'password-confirm') {
                const passwordInput = document.querySelector(input.dataset.target);

                if (input.value !== passwordInput.value) {
                    this.addInvalidity('Пароль не совпадает');
                }
            }

            if (input.classList.contains('suggestions-input')) {
                if (!input.dataset.selected) {
                    this.addInvalidity('Необходимо выбрать значение из предложенного списка');
                }
            }

            if (input.dataset.call === 'check-reduction-values') {
                const startPriceInput = document.querySelector("#start-price");

                if (Number(input.value) >= Number(startPriceInput.value)) {
                    this.addInvalidity('Сумма понижения редукциона больше чем стартовая цена.');
                }
            }
        },
        addInvalidity: function (message) {
            this.invalidities.push(message);
        },
        getInvalidities: function () {
            return this.invalidities.join('. ');
        },
    };

    function addListenerForm(form) {
        form.addEventListener('submit', function (evt) {
            let controls = form.querySelectorAll('input, select, textarea'),
                checkingCheckboxes,
                firstInvalid = false;

            window.stopSubmit = false;

            for (let i = 0; i < controls.length; i++) {
                if (controls[i].checkValidity() === false) {
                    styleInvalid(controls[i]);

                    if (!listenersControl[controls[i].id]) {
                        addListenerControl(controls[i]);

                        listenersControl[controls[i].id] = true;
                    }

                    window.stopSubmit = true;

                    if (!firstInvalid) {
                        firstInvalid = true;
                        controls[i].focus();
                    }
                } else if (
                    controls[i].type === 'checkbox' &&
                    controls[i].parentNode.classList.contains('multiply-select__option')
                ) {
                    if (checkingCheckboxes !== controls[i].id.split('_')[0]) {
                        let parentInput = document.getElementById(controls[i].id.split('_')[0]);

                        checkingCheckboxes = controls[i].id.split('_')[0];

                        if (parentInput.required === true) {
                            let checkboxes = document.querySelectorAll(`[id*=${controls[i].id.split('_')[0]}]`);

                            if (!isCheckedCheckbox(checkboxes)) {
                                styleInvalid(
                                    parentInput, 'Необходимо выбрать один или несколько вариантов'
                                );
                                if (!listenersControl[parentInput.id]) {
                                    addListenerControl(parentInput);

                                    listenersControl[parentInput.id] = true;
                                }

                                window.stopSubmit = true;

                                if (!firstInvalid) {
                                    firstInvalid = true;
                                    parentInput.focus();
                                }
                            }
                        }
                    }
                }
            }

            if (window.stopSubmit) {
                evt.preventDefault();
            }
        })
    }

    function addListenerControl(control) {
        control.addEventListener('input', function () {
            let messageInvalid = control.parentNode.querySelector('.invalid-element');

            if (control.parentNode.classList.contains('multiply-select')) {
                if (control.value) {
                    if (messageInvalid) {
                        messageInvalid.remove();
                        control.setAttribute('aria-invalid', 'false');
                        control.removeAttribute('aria-errormessage');
                    }
                } else {
                    styleInvalid(control, 'Необходимо выбрать один или несколько вариантов');
                }
            } else {
                if (control.checkValidity() === false) {
                    styleInvalid(control);
                } else {
                    if (messageInvalid) {
                        messageInvalid.remove();
                        control.setAttribute('aria-invalid', 'false');
                        control.removeAttribute('aria-errormessage');
                    }
                }
            }
        })
    }

    function isCheckedCheckbox(checkboxes) {
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                return true;
            }
        }

        return false;
    }
}

function CustomValidation() {
}

function styleInvalid(control, optionalMessage) {
    let customValidation = new CustomValidation(),
        messageInvalid = control.parentNode.querySelector('.invalid-element');

    customValidation.invalidities = [];
    customValidation.checkValidity(control);

    let customValidityMessage;

    if (!messageInvalid) {
        customValidityMessage = customValidation.getInvalidities();
        control.setAttribute('aria-invalid', 'true');
        control.setAttribute('aria-errormessage', control.id + '-error');
        control.parentNode.insertAdjacentHTML(
            'beforeend',
            `<button type="button" class="invalid-element" data-direction="${control.dataset.errorDirection || 'top'}"\n` +
            `   data-direction-sm="left" id="${control.id}-error" data-call="popover"\n` +
            `   aria-label="${customValidityMessage || optionalMessage}">\n` +
            `   <span class="visually-hidden">${optionalMessage || customValidityMessage}</span>\n` +
            `</button>`
        );
    } else if (control.dataset.invalid) {
        customValidityMessage = control.dataset.invalid + ' ' + customValidation.getInvalidities();
        messageInvalid.setAttribute('aria-label', optionalMessage || customValidityMessage);
        messageInvalid.innerHTML =  `<span class="visually-hidden">${optionalMessage || customValidityMessage}</span>`;
    } else {
        customValidityMessage = customValidation.getInvalidities();
        messageInvalid.setAttribute('aria-label', optionalMessage || customValidityMessage);
        messageInvalid.innerHTML =  `<span class="visually-hidden">${optionalMessage || customValidityMessage}</span>`;
    }
}

export function checkServerValidation(form) {
    let controls = form.querySelectorAll('input, select, textarea');

    for (let i = 0; i < controls.length; i++) {
        if (controls[i].getAttribute('aria-invalid') === "true") {
            let customValidityMessage = controls[i].dataset.invalid;

            controls[i].setAttribute('aria-errormessage', controls[i].id + '-error');
            controls[i].parentNode.insertAdjacentHTML(
                'beforeend',
                `<button type="button" class="invalid-element" data-direction="top"\n` +
                `   data-direction-sm="left" id="${controls[i].id}-error" data-call="popover"\n` +
                `   aria-label="${customValidityMessage}" aria-live="assertive">\n` +
                `   <span class="visually-hidden">${customValidityMessage}</span>\n` +
                `</button>`
            );
        }
    }
}

export {styleInvalid};
export function callModal (callers) {
    let modals = document.querySelectorAll('.modal'),
        activeElem,
        modalShow = false;

    if (!callers) {
        callers = document.querySelectorAll('[data-call="modal"]')
    }

    if (callers.length > 0) {
        for (let i = 0; i < callers.length; i++) {
            addCallEvent(callers[i]);
        }
    }

    for (let i = 0; i < modals.length; i++) {
        closeModal(modals[i]);
    }

    loopFocus();

    function addCallEvent (caller) {
        caller.addEventListener('click', function (evt) {
            evt.preventDefault();

            let targetModal = document.getElementById(caller.getAttribute('aria-controls')),
                modalContent = targetModal.querySelector('.modal__content'),
                substrate = document.createElement('div');

            modalShow = true;
            activeElem = document.activeElement;

            substrate.classList.add('substrate-modal');

            document.body.append(substrate);
            targetModal.classList.add('offset-scroll');

            if (document.documentElement.clientWidth !== window.innerWidth) {
                document.body.classList.add('offset-scroll');
            }

            document.body.classList.add('scroll-ban');

            setTimeout(function () {
                substrate.classList.add('substrate-modal_show');
            }, 10);

            setTimeout(function () {
                targetModal.classList.add('modal_show');
                targetModal.focus();
            });

            setTimeout(function () {
                modalContent.classList.add('modal__content_show');

                if (targetModal.clientWidth === window.innerWidth) {
                    targetModal.classList.remove('offset-scroll');
                }
            }, 200);

            giveData(caller, targetModal);
        })
    }

    function closeModal (modal) {
        let callersClose = modal.querySelectorAll('[data-call="close"]');

        for (let i = 0; i < callersClose.length; i++) {
            addCloseEvent(callersClose[i], modal);
        }

        addCloseEvent(modal, modal);

        function addCloseEvent (caller, modal) {
            caller.addEventListener('click', function (evt) {
                let target = evt.target;

                while (target !== this.parentNode) {
                    if (target.dataset.call === "close") {
                        close(caller, modal);
                    } else if (target.classList.contains('modal__content')) {
                        break;
                    }

                    target = target.parentNode;
                }

            });

            window.onkeydown = function (evt) {
                if (evt.code === 'Escape') {
                    if (modal.classList.contains('modal_show')) {
                        evt.preventDefault();

                        close(caller, modal);
                    }
                }
            }
        }

        function close (caller, modal) {
            let modalContent = modal.querySelector('.modal__content'),
                substrate = document.querySelector('.substrate-modal'),
                closeEvent = new Event('closeModal');

            modalShow = false;

            modalContent.classList.remove('modal__content_show');
            substrate.classList.remove('substrate-modal_show');

            setTimeout(function () {
                modal.classList.remove('modal_show');
                substrate.remove();
                removeData(modal);
            }, 200);

            document.body.classList.remove('scroll-ban');
            document.body.classList.remove('offset-scroll');

            activeElem?.focus();

            document.dispatchEvent(closeEvent);
        }
    }

    function giveData (caller, modal) {
        let dataGive = caller.dataset.give;

        if (dataGive) {
            dataGive = dataGive.replace(/[\n\r]/g, ' ');

            let giveItems = dataGive.split(';; '),
                giveObject = {},
                takeElems = modal.querySelectorAll('[data-take]');

            for (let i = 0; i < giveItems.length; i++) {
                let key = giveItems[i].split(':: ')[0].replace(/\s{2,}/g, ''),
                    value = giveItems[i].split(':: ')[1].trim();

                if (value[0] === '[' && value[value.length - 1] === ']') {
                    value = JSON.parse(value);
                }

                giveObject[key] = value;
            }

            for (let key in giveObject) {
                if (giveObject.hasOwnProperty(key)) {
                    for (let i = 0; i < takeElems.length; i++) {
                        let takeThings = takeElems[i].dataset.take.split(', ');

                        for (let j = 0; j < takeThings.length; j++) {
                            if (takeThings[j].indexOf(key) + 1 && key === 'text') { // give text
                                if (typeof giveObject[key] === 'string') {
                                    takeElems[i].textContent = giveObject[key];
                                } else if (typeof giveObject[key] === 'object') {
                                    let takeString = takeElems[i].dataset.take,
                                        index = takeString.slice(takeString.indexOf('[') + 1, takeString.indexOf(']'));

                                    takeElems[i].textContent = giveObject[key][Number(index)];
                                }
                            } else if (takeThings[j].indexOf(key) + 1 && key === 'class') { // give class
                                if (typeof giveObject[key] === 'string') {
                                    takeElems[i].classList.toggle(giveObject[key]);

                                    if (takeElems[i].classList.contains(giveObject[key])) {
                                        takeElems[i].setAttribute('data-add-class', giveObject[key]);
                                    } else {
                                        takeElems[i].setAttribute('data-remove-class', giveObject[key]);
                                    }
                                } else if (typeof giveObject[key] === 'object') {
                                    if (takeThings[j] === key) {
                                        let addArray = [],
                                            removeArray = [];

                                        for (let k = 0; k < giveObject[key].length; k++) {
                                            takeElems[i].classList.toggle(giveObject[key][k]);

                                            if (takeElems[i].classList.contains(giveObject[key][k])) {
                                                addArray.push(giveObject[key][k]);
                                            } else {
                                                removeArray.push(giveObject[key][k]);
                                            }
                                        }

                                        if (addArray.length > 0) {
                                            takeElems[i].dataset.addClass = '[' + String(addArray) + ']';
                                        }

                                        if (removeArray.length > 0 ) {
                                            takeElems[i].dataset.removeClass = '[' + String(removeArray) + ']';
                                        }
                                    } else {
                                        let takeString = takeElems[i].dataset.take,
                                            index = takeString
                                                .slice(takeString.indexOf('[') + 1, takeString.indexOf(']'));

                                        takeElems[i].classList.toggle(giveObject[key][Number(index)]);

                                        if (takeElems[i].classList.contains(giveObject[key][Number(index)])) {
                                            takeElems[i].dataset.addClass = giveObject[key][Number(index)];
                                        } else {
                                            takeElems[i].dataset.removeClass = giveObject[key][Number(index)];
                                        }
                                    }
                                }
                            } else if (takeThings[j].indexOf(key) + 1) { // give attribute
                                if (typeof giveObject[key] === 'string') {
                                    takeElems[i].setAttribute(key, giveObject[key]);
                                } else if (typeof giveObject[key] === 'object') {
                                    let takeString = takeElems[i].dataset.take,
                                        index = takeString.slice(takeString.indexOf('[') + 1, takeString.indexOf(']'));

                                    takeElems[i].setAttribute(key, giveObject[key][Number(index)]);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    function removeData (modal) {
        let takeElems = modal.querySelectorAll('[data-take]');

        if (takeElems.length > 0) {
            for (let i = 0; i < takeElems.length; i++) {
                let takeThings = takeElems[i].dataset.take.split(', ');

                for (let j = 0; j < takeThings.length; j++) {
                    if (takeThings[j].indexOf('text') + 1) { // remove text
                        takeElems[i].textContent = '';
                    } else if (!(takeThings[j].indexOf('class') + 1)) { // remove attribute
                        if (takeThings[j].indexOf('[') + 1) {
                            takeElems[i].removeAttribute(takeThings[j].slice(0, takeThings[j].indexOf('[')));
                        } else {
                            takeElems[i].removeAttribute(takeThings[j]);
                        }
                    }
                }
            }

            let addClassElems = modal.querySelectorAll('[data-add-class]'),
                removeClassElems = modal.querySelectorAll('[data-remove-class]');

            for (let i = 0; i < addClassElems.length; i++) { // add class
                let value = addClassElems[i].dataset.addClass;

                if (value[0] === '[' && value[value.length - 1] === ']') {
                    value = value.slice(1, value.length - 1).split(', ');

                    for (let j = 0; j < value.length; j++) {
                        addClassElems[i].classList.remove(value[j]);
                    }
                } else {
                    addClassElems[i].classList.remove(value);
                }

                addClassElems[i].removeAttribute('data-add-class');
            }

            for (let i = 0; i < removeClassElems.length; i++) { // remove class
                let value = removeClassElems[i].dataset.removeClass;

                if (value[0] === '[' && value[value.length - 1] === ']') {
                    value = value.slice(1, value.length - 1).split(',');

                    for (let j = 0; j < value.length; j++) {
                        removeClassElems[i].classList.add(value[j]);
                    }
                } else {
                    removeClassElems[i].classList.add(value);
                }

                removeClassElems[i].removeAttribute('data-remove-class');
            }
        }
    }

    function loopFocus () {
        document.addEventListener('focusin', function (evt) {
            let modal = findModalShow();

            if (modalShow) {
                let target = evt.target;

                if (target !== modal && target !== document && !hasElem(target, modal)) {
                    modal.focus();
                }
            }
        })
    }

    function hasElem (elem, parent) {
        let descendants = parent.querySelectorAll('*');

        for (let i = 0; i < descendants.length; i++) {
            if (elem === descendants[i]) {
                return true;
            }
        }
    }

    function findModalShow() {
        let modals = document.querySelectorAll('.modal');

        for (let i = 0; i < modals.length; i++) {
            if (modals[i].classList.contains('modal_show')) {
                return modals[i];
            }
        }
    }
}
const MAX_FILE_SIZE = 30000000;

export function customFileUpload(input) {
    if (!input) {
        const customFiles = document.querySelectorAll('.custom-file-input');

        if (customFiles.length > 0) {
            for (let i = 0; i < customFiles.length; i++) {
                let fileInput = customFiles[i].querySelector('input[type="file"]');

                if (fileInput) {
                    addListener(fileInput);
                }
            }
        }
    } else {
        addListener(input);
    }

    function addListener(elem) {
        elem.addEventListener('change', function (evt) {
            let files = evt.target.files,
                label = elem.parentNode.querySelector('label'),
                isFileSizeOutOfRange = false,
                maxFileSize = elem.dataset.maxSize ? Number(elem.dataset.maxSize) : MAX_FILE_SIZE;

            for (let i = 0; i < files.length; i++) {
                if (files[i].size > maxFileSize) {
                    isFileSizeOutOfRange = true;
                    break;
                }
            }

            if (files.length < 1) {
                clearInput(elem, true);
                setValidInput(elem);
            } else if (files.length > elem.dataset.limit) {
                clearInput(elem, true);
                setInvalidInput(elem, `Превышено максимальное количество загружаемых файлов: ${elem.dataset.limit}`);
            } else if (isFileSizeOutOfRange) {
                clearInput(elem, true);
                setInvalidInput(elem, `Как минимум один файл превышает допустимый размер в ${maxFileSize / 1000000}мб`);
            } else {
                let fileName = document.createElement('span');

                clearInput(elem, false);
                setValidInput(elem);

                fileName.classList.add('custom-file-input__file-name');
                elem.parentNode.classList.add('custom-file-input_uploaded');

                if (files.length === 1) {
                    fileName.textContent = files[0].name;
                    fileName.title = files[0].name;
                } else {
                    let stringTitle = '';

                    fileName.textContent = `Файлы: ${files.length}`;

                    for (let i = 0; i < files.length; i++) {
                        if (i !== files.length - 1) {
                            stringTitle += files[i].name + ', ';
                        } else {
                            stringTitle += files[i].name;
                        }
                    }

                    fileName.title = stringTitle;
                }

                label.append(fileName);
            }
        });
    }

    function clearInput(elem, isFull) {
        let fileName = elem.parentNode.querySelector('.custom-file-input__file-name');

        if (isFull) {
            elem.parentNode.classList.remove('custom-file-input_uploaded');
            elem.value = '';
        }

        if (fileName) {
            fileName.remove();
        }
    }

    function setInvalidInput(elem, errorMessage) {
        elem.setAttribute('aria-invalid', 'true');
        elem.setAttribute('aria-errormessage', elem.id + '-error');
        elem.parentNode.insertAdjacentHTML(
            'beforeend',
            `<button type="button" class="invalid-element" data-direction="${elem.dataset.errorDirection || 'top'}"\n` +
            `   data-direction-sm="left" id="${elem.id}-error" data-call="popover"\n` +
            `   aria-label="${errorMessage}">\n` +
            `   <span class="visually-hidden">${errorMessage}</span>\n` +
            `</button>`
        );
    }

    function setValidInput(elem) {
        const invalidElement = elem.parentNode.querySelector('.invalid-element');

        elem.removeAttribute('aria-invalid');
        elem.removeAttribute('aria-errormessage');

        if (invalidElement) {
            invalidElement.remove();
        }
    }
}
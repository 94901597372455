export function customImageUpload() {
    let customFiles = document.querySelectorAll('.custom-image-input');
    const checkFileEvent = new Event('check-file');

    if (customFiles.length > 0) {
        for (let i = 0; i < customFiles.length; i++) {
            let fileInput = customFiles[i].querySelector('input[type="file"]'),
                clearButton = customFiles[i].querySelector('.custom-image-input__clear');

            if (fileInput) {
                addListener(fileInput);
            }

            if (clearButton) {
                clearButton.addEventListener('click', function (evt) {
                    evt.preventDefault();

                    clearInput(fileInput, true);
                })
            }
        }
    }

    function addListener(elem) {
        elem.addEventListener('change', async function (evt) {
            let file = evt.target.files[0],
                label = elem.parentNode.querySelector('label');

            if (!file) {
                clearInput(elem, true);
                removeInvalidMessage(elem);
            } else if (!file.type.startsWith('image/')) {
                clearInput(elem, true);
                addInvalidMessage(elem, 'Недопустимый формат файла');
            } else if (elem.dataset.maxSize && elem.dataset.maxSize < file.size) {
                clearInput(elem, true);
                addInvalidMessage(elem, `Превышен максимальный размер файла: ${elem.dataset.maxSize / 1000} кб`);
            } else {
                clearInput(elem);
                removeInvalidMessage(elem);

                const isResolutionValid = await checkImageResolution(file, elem);

                if (!isResolutionValid) {
                    clearInput(elem, true);
                    addInvalidMessage(
                        elem,
                        `Превышено максимальное разрешение изображения: ${elem.dataset.maxWidth}x${elem.dataset.maxHeight} px`
                    );
                } else {
                    let img = document.createElement('img'),
                        reader = new FileReader(),
                        spinner = elem.parentNode.querySelector('.spinner');

                    elem.parentNode.classList.add('custom-image-input_uploaded');

                    setTimeout(function () {
                        spinner.classList.add('custom-image-input__spinner_show');
                        img.file = file;
                        img.classList.add('custom-image-input__image');
                        label.append(img);

                        reader.onload = (function(uploadedFile) {
                            return function(e) {
                                uploadedFile.src = e.target.result;
                                uploadedFile.alt = file.name;
                                uploadedFile.title = file.name;
                                spinner.classList.remove('custom-image-input__spinner_show');
                            };
                        })(img);

                        reader.readAsDataURL(file);

                        addClearButton(label, elem);
                    },300);

                    elem.dispatchEvent(checkFileEvent);
                }
            }
        })
    }

    function addClearButton(label, input) {
        let clearButton = document.createElement('button'),
            clearSpan = document.createElement('span'),
            clearText = 'Очистить';

        clearButton.type = 'button';
        clearButton.classList.add('custom-image-input__clear');
        clearButton.title = clearText;
        clearSpan.classList.add('visually-hidden');
        clearSpan.textContent = clearText;

        label.append(clearButton);
        clearButton.append(clearSpan);

        clearButton.addEventListener('click', function (evt) {
            evt.preventDefault();

            clearInput(input, true);
        })
    }

    function clearInput(elem, deep) {
        let img = elem.parentNode.querySelector('.custom-image-input__image'),
            clearButton = elem.parentNode.querySelector('.custom-image-input__clear');

        elem.parentNode.classList.remove('custom-image-input_uploaded');

        if (deep) {
            elem.value = '';
        }

        if (img) {
            img.remove();
        }

        if (clearButton) {
            clearButton.remove();
        }

        elem.dispatchEvent(checkFileEvent);
    }

    function addInvalidMessage(input, message) {
        const invalidMessage = document.createElement('button');

        invalidMessage.type = 'button';
        invalidMessage.className = 'invalid-element';
        invalidMessage.dataset.direction = 'top';
        invalidMessage.dataset.directionSm = 'left';
        invalidMessage.id = `${input.id}-error`;
        invalidMessage.dataset.call = 'popover';
        invalidMessage.setAttribute('aria-label', message);
        invalidMessage.innerHTML = `<span class="visually-hidden">${message}</span>`
        input.setAttribute('aria-invalid', 'true');
        input.setAttribute('aria-errormessage', `${input.id}-error`);
        input.parentNode.append(invalidMessage)
    }

    function removeInvalidMessage(input) {
        const invalidMessage = input.parentNode.querySelector('.invalid-element');

        if (invalidMessage) {
            invalidMessage.remove();
            input.removeAttribute('aria-invalid');
            input.removeAttribute('aria-errormessage');
        }
    }

    async function checkImageResolution(file, elem) {
        const image = new Image();

        function load() {
            return new Promise(function(resolve) {
                image.onload = resolve;
            });
        }

        image.src = window.URL.createObjectURL(file);

        return await load().then(function() {
            if (elem.dataset.maxWidth) {
                return elem.dataset.maxWidth >= image.width;
            }

            if (elem.dataset.maxHeight) {
                return elem.dataset.maxHeight >= image.height;
            }

            return true;
        });
    }
}
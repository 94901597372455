export function customSelect (optionalCustomSelect) {
    if (!optionalCustomSelect) {
        let customSelects = document.querySelectorAll('.custom-select');

        if (customSelects.length > 0) {
            for (let i = 0; i < customSelects.length; i++) {
                setTimeout(function () {
                    addBehavior(customSelects[i]);
                })
            }
        }
    } else {
        addBehavior(optionalCustomSelect);
    }


    function focusSelectEvent (select, label) {
        select.addEventListener('focus', function () {
            label.classList.remove('state_unselect');
        })
    }

    function changeSelectEvent (select, label) {
        select.addEventListener('change', function () {
            if (select.value && select.value !== 'empty') {
                label.classList.remove('state_unselect');
                select.classList.remove('state_unselect');
            } else {
                select.classList.add('state_unselect');
            }
        })
    }

    function blurSelectEvent (select, label) {
        select.addEventListener('blur', function () {
            if (!select.value || select.value === 'empty') {
                label.classList.add('state_unselect');
                select.classList.add('state_unselect');
            }
        })
    }

    function addBehavior (customSelect) {
        let label = customSelect.querySelector('label'),
            select = customSelect.querySelector('select');

        if (!select.value || select.value === 'empty') {
            label.classList.add('state_unselect');
            select.classList.add('state_unselect');
        }

        focusSelectEvent(select, label);
        changeSelectEvent(select, label);
        blurSelectEvent(select, label);
    }
}
export function multiplySelect (optionalMultiplySelect) {
    if (!optionalMultiplySelect) {
        let multiplySelects = document.querySelectorAll('.multiply-select:not(.multiply-select_disabled)');

        if (multiplySelects.length > 0) {
            for (let i = 0; i < multiplySelects.length; i++) {
                multiplySelects[i].classList.add('multiply-select_enable');

                let input = multiplySelects[i].querySelector('.multiply-select > input'),
                    options = multiplySelects[i].querySelector('.multiply-select__options'),
                    optionInputs = options.querySelectorAll('input'),
                    checkAllInput = multiplySelects[i].querySelector('input[type="checkbox"][value="all"]');

                clickSelectEvent(input, options);
                spaceSelectEvent(input, options);

                if (checkAllInput) {
                    checkAllInput.addEventListener('change', function () {

                    });
                }

                for (let k = 0; k < optionInputs.length; k++) {
                    inputOptionEvent(input, optionInputs[k], optionInputs);
                    initCheckedSelect(input, optionInputs[k], optionInputs);
                }
            }
        }
    } else {
        optionalMultiplySelect.classList.add('multiply-select_enable');

        let input = optionalMultiplySelect.querySelector('.multiply-select > input'),
            options = optionalMultiplySelect.querySelector('.multiply-select__options'),
            optionInputs = options.querySelectorAll('input'),
            checkAllInput = optionalMultiplySelect.querySelector('input[type="checkbox"][value="all"]');

        clickSelectEvent(input, options);
        spaceSelectEvent(input, options);

        for (let k = 0; k < optionInputs.length; k++) {
            inputOptionEvent(input, optionInputs[k], optionInputs);
        }
    }

    function clickSelectEvent(input, options) {
        input.addEventListener('click', function () {
           actionClickSelectEvent(input, options)
        })
    }
    
    function spaceSelectEvent (input, options) {
        input.addEventListener('keyup', function (evt) {
            if (evt.code === 'Space' ||
                evt.key === 'Spacebar' || //for IE
                evt.key === ' ') { //for Edge
                evt.stopPropagation();
                actionClickSelectEvent(input, options)
            }
        })
    }

    function actionClickSelectEvent(input, options) {
        let substrate = document.createElement('div'),
            firstOption = options.querySelector('input');

        options.parentNode.appendChild(substrate);

        if (!input.classList.contains('open')) {
            input.classList.add('open');

            substrate.classList.add('multiply-select__substrate');
            substrate.tabIndex = 0;

            substrate.addEventListener('mousedown', function () {
                closeSelect();
            });

            substrate.addEventListener('focus', function () {
                closeSelect();
            });

            let coordinateBottom =
                    document.documentElement.clientHeight - input.getBoundingClientRect().y - input.scrollHeight,
                coordinateBottomMs =
                    document.documentElement.clientHeight - input.getBoundingClientRect().top - input.scrollHeight, //for Edge
                coordinateTop = input.getBoundingClientRect().y,
                coordinateTopMs = input.getBoundingClientRect().top, //for Edge
                optionsHeight = options.scrollHeight;

            if (
                ((coordinateBottom && coordinateBottom < optionsHeight) || coordinateBottomMs < optionsHeight) &&
                ((coordinateTop && coordinateTop > optionsHeight) || coordinateTopMs > optionsHeight)
            ) {
                options.classList.add('multiply-select__options_show_top');
            } else {
                options.classList.add('multiply-select__options_show_bottom');
            }

            firstOption?.focus();
        } else {
            closeSelect();
        }

        function closeSelect() {
            input.classList.remove('open');
            options.classList.remove('multiply-select__options_show_top');
            options.classList.remove('multiply-select__options_show_bottom');

            let substrate = document.querySelector('.multiply-select__substrate');

            substrate.remove();
            input.focus();
        }
    }
}

export function inputOptionEvent (textInput, optionInput, inputsArray) {
    optionInput.addEventListener('change', function () {
        writeCheckedOptions(textInput, optionInput, inputsArray);
        onOptionsChange(textInput, optionInput, inputsArray);
    })
}

export function initCheckedSelect (textInput, optionInput, inputsArray) {
    writeCheckedOptions(textInput, optionInput, inputsArray);
}

function onOptionsChange(textInput, optionInput, inputsArray) {
    if (optionInput.value === 'all') {
        if (optionInput.checked) {
            for (let i = 0; i < inputsArray.length; i++) {
                inputsArray[i].checked = true;
            }
        } else {
            for (let i = 0; i < inputsArray.length; i++) {
                inputsArray[i].checked = false;
            }
        }
    } else {
        let checkAllOption;

        for (let i = 0; i < inputsArray.length; i++) {
            if (inputsArray[i].value === 'all') {
                checkAllOption = inputsArray[i];
                break;
            }
        }

        if (checkAllOption) {
            checkAllOption.checked = false;
            writeCheckedOptions(textInput, optionInput, inputsArray);
        }
    }
}

function writeCheckedOptions(textInput, optionInput, inputsArray) {
    let result = '',
        counter = 0;

    if (optionInput.value !== 'all') {
        for (let i = 0; i < inputsArray.length; i++) {
            if (inputsArray[i].value !== 'all') {
                let labelText = inputsArray[i].parentNode.querySelector('label').textContent.trim();

                if (inputsArray[i].checked) {
                    if (counter === 0) {
                        result += labelText;
                    } else {
                        result += ', ' + labelText;
                    }

                    counter += 1;
                }
            }
        }
    } else {
        if (optionInput.checked) {
            result = optionInput.parentNode.querySelector('label').textContent;
        }
    }

    textInput.value = result;
    textInput.dispatchEvent(new Event('input'));
}
const treeCheckboxes = document.querySelectorAll(`[data-call="checkbox-tree"]`);

function handleCheckbox(checkbox) {
    if (checkbox.dataset.checkboxParent) {
        handleParentCheckbox(checkbox);
    }

    if (checkbox.dataset.checkboxChild) {
        handleChildCheckbox(checkbox);
    }
}

function handleParentCheckbox(checkbox) {
    const children = document.querySelectorAll(`[data-checkbox-child=${checkbox.dataset.checkboxParent}]`);

    function onParentCheckbox() {
        for (let i = 0; i < children.length; i++) {
            children[i].checked = checkbox.checked;
        }
    }

    checkbox.addEventListener(`change`, onParentCheckbox);
}

function handleChildCheckbox(checkbox) {
    const children = document.querySelectorAll(`[data-checkbox-child=${checkbox.dataset.checkboxChild}]`);
    const parent = document.querySelector(`[data-checkbox-parent=${checkbox.dataset.checkboxChild}]`);

    function onChildCheckbox() {
        let isAllChecked = true;
        let isAllUnchecked = true;

        for (let i = 0; i < children.length; i++) {
            if (children[i].checked) {
                isAllUnchecked = false;
            } else {
                isAllChecked = false;
            }
        }

        if (isAllChecked) {
            parent.indeterminate = false;
            parent.checked = true;

            return;
        }

        if (isAllUnchecked) {
            parent.indeterminate = false;
            parent.checked = false;

            return;
        }

        parent.indeterminate = true;
    }

    onChildCheckbox();
    checkbox.addEventListener(`change`, onChildCheckbox);
}

function initCheckboxTree() {
    for (let i = 0; i < treeCheckboxes.length; i++) {
        handleCheckbox(treeCheckboxes[i]);
    }
}

export {initCheckboxTree};